import { DotsThreeVertical, CircleNotch } from "phosphor-react"
import { useEffect } from "react"
import { useState } from "react"
import EntityService from "../api/EntityService"
import ResourcesUploadBox from "../components/ResourcesUploadBox"
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { useSelectedPlaylist } from "../hooks/useSelectedPlaylist"
import { Content, Item, Portal, Root, Trigger } from "@radix-ui/react-dropdown-menu"
import { toastError } from "../api/apiUtils"
import { useTranslation } from "react-i18next"

const ResourcesPage = () => {
    const { t } = useTranslation()
    const [resources, setResources] = useState([])
    const [isLoading, setIsloading] = useState(true)
    // const { playlists, isSelectPlaylistModalLoading, isAssignToPlaylistOpen, setIsAssignToPlaylistOpen, errorMsg } = useSelectedPlaylist()
    const usePlaylist = useSelectedPlaylist()
    
    const init = () => {
        setIsloading(true)
        EntityService.all(1, 500)
        .then((res) => setResources(res || []))
        .catch((e) => console.error(e))
        .finally(() => setIsloading(false))
    }

    useEffect(() => {
        init()
    }, [])

    const deletePlaylist = (item) => {
        EntityService.distroy(item.id)
        .then((res) => init())
        .catch((e) => toastError(e?.toString()))
    }

    return(
        <div>
            <h1 className="font-bold text-2xl py-4">{t('menu_resources')}</h1>
            <ResourcesUploadBox append={(obj) => setResources((prev) => [obj, ...prev])}/>
            

            <Drawer open={usePlaylist.state.isAddModalOpen} onClose={() => usePlaylist.action.closeAddModal()} direction="bottom" className="p-2">
                <div className="text-center pb-2">
                    {t('add_to_a_playlist')}
                </div>
                <div hidden={!usePlaylist.state.isLoading} className="animate-spin w-fit m-auto mt-8"><CircleNotch size={32} color="blue"/></div>
                <div hidden={usePlaylist.state.errorMsg?.length === 0} className="text-center text-red-500 font-normal mt-8">{usePlaylist.state.errorMsg?.toString()}</div>
                <div className={`h-5/6 ${usePlaylist.state.isLoading || usePlaylist.state.errorMsg ? "hidden" : "flex"} flex-grow-0 flex-shrink-0 w-full rtl:space-x-reverse space-x-4 overflow-x-auto`}>
                    {usePlaylist.state.playlists?.map((item) => (
                        <div key={item.id} className="border-2 border-black h-full w-52 text-clip  min-w-[13rem]x flex-grow-0 flex-shrink-0 rounded-md p-2 flex justify-center items-center" onClick={() => usePlaylist.action.addEntityToPlaylist(item)}>
                            <div role={'button'} className="text-center select-none cursor-pointer">{item.name}</div>
                        </div>
                    ))}
                </div>
            </Drawer>


            <div hidden={!isLoading} className="animate-spin w-fit m-auto"><CircleNotch size={32} color="blue"/></div>
            <div className={`${resources.length === 0 ? 'hidden' : 'grid'} grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4`}>
                {
                    resources.map((item) => (
                        <div key={item.id} className="border p-1 rounded-lg flex flex-col">
                            <div className="grow grid place-content-center">
                                {
                                    item.entityType === "1"?
                                    <img src={item.entityFile || "https://via.placeholder.com/1920x1080"} className="m-auto p-1 aspect-video object-contain" alt="img"/>
                                    :
                                    <video src={item.entityFile} controls />
                                }
                            </div>
                            <div className="flex w-full items-center justify-between py-1 px-1">
                                <p className="text-sm md:text-lg">{item.entityTitle || new Date(item.dateCreated).toDateString()}</p>

                                <Root dir="auto">
                                    <Trigger>
                                        <DotsThreeVertical size={24} className="-ml-2" />
                                    </Trigger>
                                    <Portal>
                                        <Content className="bg-gray-200 px-3 py-1 rounded">
                                            <Item  onClick={() => usePlaylist.action.openAddModal(item)}>
                                                <button className="select-none">{t('dropdown_assignToPlaylist')}</button>
                                            </Item>
                                            <Item onClick={() => deletePlaylist(item)}>
                                                <button className="select-none">{t('dropdown_delete')}</button>
                                            </Item>
                                            {/* <Arrow></Arrow> */}
                                        </Content>
                                    </Portal>
                                </Root>
                            </div>
                        </div>
                    ))
                }
            </div>


           
        </div>
    )
}

export default ResourcesPage