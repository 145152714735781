import { CircleNotch } from "phosphor-react"
import { useEffect } from "react"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import PlaylistService from "../api/PlaylistService"
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useOrdringSystem } from "../hooks/useOrdringSystem"
import DragableEntityComponents from "../components/DragableEntityComponents"
import { toastError, toastObjectUpdated } from "../api/apiUtils"
import { useTranslation } from "react-i18next"


const grid = 8;

const getItemStyle = (draggableStyle, isDragging) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    
    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'white',
    border: '1px solid black',
    
    // styles we need to apply on draggables
    ...draggableStyle
});
  
const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? 'lightblue' : 'lightgrey',
    background: 'white',
    padding: grid,
    width: 250,
    // display:'flex',
    // flexDirection:'column'
});


const PlaylistDetailsPage = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const urlParams = useParams()
    const [data, setData] = useState()
    const [isLoading, setIsloading] = useState(true)
    const {onDragEnd, updateOrder} = useOrdringSystem(data, setData)


    const init = () => {
        setIsloading(true)
        PlaylistService.get(urlParams.playlistID)
        .then((res) => {setData(res)})
        .catch((e) => console.error(e))
        .finally(() => setIsloading(false))
    }
    
    useEffect(() => {
        init()
    }, [urlParams.playlistID])


    const save = () => {
        // if(data.name.length < 3) { return toastError("e")}
        const payload = data
        payload.content = updateOrder()

        PlaylistService.updatePlaylist(payload)
        .then((res) => {
            setData(Object.assign({}, res))
            toastObjectUpdated(t('toast_playlist_updated'))
        })
        .catch((e) => {
            console.error(e)
            toastError(e.message)
        })
    }

    const onPlaylistItemPropChange = (item, feild, value) => {
        setData((prev) => {
            prev.content = prev.content.map((x) => {
                if(x.id === item.id && feild !== undefined){
                    x[feild] = value
                }

                return x
            })
             

            return Object.assign({},prev)
        })
    }

    const onRemoveItemFromPlaylist = (id) => {
        PlaylistService.removeItemFromPlaylist(id)
        .then((res) => {
            if(res){
                init()
            }
            else{
                toastError(t('error'))
            }
        })
        .catch((e) => console.error(e))
    } 

    const onDeletePlaylist = () => {
        PlaylistService.distroy(urlParams.playlistID)
        .then((res) => navigate('/playlists'))
        .catch((e) => console.error(e))
    }

    return(
        <div>
            <div className="flex justify-between items-center">
                <h1 className="font-bold text-2xl py-4">{data?.name || t('playlist_details')}</h1>
                <button onClick={() => save()}>{t('button_save')}</button>
            </div>


            <div hidden={!isLoading} className="animate-spin w-fit m-auto"><CircleNotch size={32} color="blue"/></div>
            <div className={`${isLoading ? 'hidden' : 'flex flex-col md:flex-row items-start  md:justify-around '} m-auto`} hidden={isLoading}>
                <div className="w-full md:w-1/2">
                    <div className="p-2 my-4 m-auto max-w-sm md:max-w-md">
                        <div className="space-y-1 mb-2">
                            <div>{t('input_playlist_name')}</div>
                            <input value={data?.name} onChange={(e) => setData((prev) => Object.assign({},prev, {name:e.target.value}) )} className="border p-2 rounded-md focus:outline-none w-full"/>
                        </div>

                        
                        <div className="flex justify-center items-center">
                            <button onClick={() => onDeletePlaylist()} className="text-center text-red-500">
                                {t('button_delete')}
                            </button>
                        </div>
                    </div>
                </div>
                
                <div className="flex flex-col justify-center items-center border md:justify-start max-w-md p-2 h-fit w-full md:w-1/2 m-auto">
                    <div className="select-none my-2">
                        <div className="text-center">{t('playlist_content')}</div>
                        <p className="text-center text-sm">HOW_TO_REORDER_PLACEHOLDER</p>
                    </div>
                    <div>
                        {
                            !isLoading ?
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (


                                        <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
                                            <div className="text-center text-sm text-gray-500 mt-7" hidden={data.content?.length > 0}>NO_CONTENT_PLACEHOLDER</div>
                                            {data.content?.map((item, index) => (
                                                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div>
                                                            <div ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps} style={getItemStyle(provided.draggableProps.style, snapshot.isDragging)}>
                                                                {/* {item.name || "item "} -- {item.id} */}
                                                                <DragableEntityComponents item={item} index={index} onRemove={onRemoveItemFromPlaylist} onChange={onPlaylistItemPropChange} />

                                                                
                                                            </div>
                                                            {/* {provided.placeholder} place holder */}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}


                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            : ""
                        }
                    </div>
                </div>

                
            </div>
        </div>
    )
}

export default PlaylistDetailsPage