import { CircleNotch } from "phosphor-react"
import { useEffect } from "react"
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import PlaylistService from "../api/PlaylistService"
import { useTranslation } from "react-i18next"


const PlaylistPage = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [playlists, setPlaylists] = useState([])
    const [isLoading, setIsloading] = useState(false)
    

    useEffect(() => {
        setIsloading(true)
        PlaylistService.all(1, 100)
        .then((res) => setPlaylists(res || []))
        .catch((e) => console.error(e))
        .finally(() => setIsloading(false))
    }, [])

    return(
        <div>
            <div className="flex justify-between items-center">
                <h1 className="font-bold text-2xl py-4">{t('menu_playlists')}</h1>
                <button onClick={() => navigate('/new-playlist')}>{t('button_newPlaylist')}</button>
            </div>

            <div hidden={!isLoading} className="animate-spin w-fit m-auto"><CircleNotch size={32} color="blue"/></div>
            <div className={`${playlists.length === 0 ? 'hidden' : 'block'} space-y-4`}>
                {
                    playlists.map((item) => (
                        <div key={item.id} className="border border-black p-1 rounded-lg flex flex-col">
                            <Link to={`/playlists/${item.id}`} state={{...item}} className="flex w-full items-center justify-between py-1 px-1">
                                <p className="text-lg">{item.name || new Date(item.dateCreated).toDateString()}</p>
                                {/* <DotsThreeVertical size={24} className="-ml-2"/> */}
                            </Link>
                        </div>

                    ))
                }
            </div>
        </div>
    )
}

export default PlaylistPage