import { useState } from "react"
import UserService from "../api/UserService"
import { toastError } from "../api/apiUtils"
import { useEffect } from "react"



export const useSettings = () => {
    const [isLoading, setIsLoading]         = useState(true)
    const [isLogoEnabled, setIsLogoEnabled] = useState(false)
    
    const [logo, setLogo] = useState(undefined)
    const [settings, setSettings] = useState(undefined)


    const onLogoChange = (e) => {

    }


    const init = () => {
        setIsLoading(true)
        UserService.getSettings()
        .then((res) => setSettings(res))
        .catch((e) => toastError(e.message))
        .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        init()
    }, [])

    return {
        logo,
        onLogoChange,
        isLoading,
        settings
    }
}