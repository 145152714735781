export const i18nResources = {
    en:{
        translation:{
            project_name   :"Website Name",
            menu_homepage  :"Home Page",
            menu_resources :"Manage Resources",
            menu_devices   :"Manage Screens",
            menu_playlists :"Manage Screen Playlists",
            menu_settings  :"Settings",
            menu_new_device:"New Device",
            menu_new_playlist:"New Playlist",
            menu_account_info:"Account Details",
            menu_device_details:"Device Details",
            menu_image_editor:"Create an Image",

            activate_a_package:"Activate a package",
            subscription_packages:"Subscription Packages",
            sar:"SAR",

            section_playlist:"Playlist",
            section_reset_password:"Reset Password",

            button_uploadfile:"Upload an Image or video",
            button_newDevice:"New Device",
            button_newPlaylist:"New Playlist",
            button_save:"Save",
            button_delete:"Delete",
            button_logout:"Logout",
            button_login:"Login",
            button_reset_password:"Reset Password",
            button_create_account:"Create Account",
            button_buy_a_package:"Buy an activation code",
            button_next:"Next",
            button_previous:"Previous",
            button_reset_password_submet:"Reset",
            button_horizontal:"Horizontal",
            button_vertical:"Vertical",
            button_add:"Add",
            

            dropdown_assignToPlaylist:"Assign to a playlist",
            dropdown_delete:"Delete",

            select_no_playlist_assigned:"Not assigned",

            input_device_name:"Name",
            input_device_pin:"Pin",
            input_playlist_name:"Name",
            input_login_email:"Email",
            input_login_password:"Password",
            input_login_remember_me:"Remember login credentials",
            input_establishment_name:"Establishment name",
            input_activation_code:"Activation code",
            input_phone_number:"Phone Number",
            input_subscription_type:"Subscription type",
            input_subscription_end_date:"Subscription expiration date",
            input_establishment_logo:"Logo",
            input_playlist:"Playlist",
            input_image_text:"Text",

            playlist_content:"Playlist Content",
            playlist_details:"Playlist Details",
            add_to_a_playlist:"Add to a Playlist",


            entity_duration:"Duration",
            entity_volume:"Volume",
            entity_seconds:"sec",

            settings_account_section:"Account",
            settings_subscription_section:"Subscription Details",
            settings_whitelabel_section:"Logo",

            stats_card_activeScreens:"Active screens count",
            stats_card_playlistCount:"Playlists count",

            toast_playlist_updated:"Playlist Updated",
            toast_updated:"Updated Successfully",
            toast_deleted:"Deleted",

            error:"Error",
            error_weak_password:"Use stronger password",
            error_establishment_length:"Establishment name must be more than 3 characters",
            error_activation_code_invalid:"Invalid activation code",
            error_not_allowed_to_delete:'Not Allowed to Delete',
            error_not_allowed_to_edit:'Not Allowed to Edit',
            password_or_email_is_incorrect:"Email or password is incorrect",
            error_locked_in_this_tier:"Not included in your subscription",
            error_email_not_valid:"Email not valid",
            error_device_already_registered:"Device Already Registered",
            error_user_profile_not_completed:"Profile is not completed",
            error_unsupported_media:"Unsupported file type"
        }
    },
    ar: {
        translation:{
            project_name   :"اسم الموقع",
            menu_homepage  :"الشاشة الرئيسية",
            menu_resources :"إدارة ملفات الوسائط",
            menu_devices   :"إدارة الشاشات",
            menu_playlists :"إدارة قوائم الوسائط",
            menu_settings  :"الإعدادات",
            menu_new_device:"شاشة جديدة",
            menu_new_playlist:"قائمة تشغيل جديدة",
            menu_account_info:"تفاصيل الحساب",
            menu_device_details:"معلومات الجهاز",
            menu_image_editor:"إنشاء صور",

            activate_a_package:"تفعيل الباقة",  
            subscription_packages:"تفاصيل الباقات",
            sar:"ريال",
            
            section_playlist:"قائمة التشغيل",
            section_reset_password:"إعادة تعين كلمة السر",

            button_uploadfile:"رفع ملف جديد",
            button_newDevice:"شاشة جديدة",
            button_newPlaylist:"قائمة جديدة",
            button_save:"حفظ",
            button_delete:"حذف",
            button_logout:"تسجيل الخروج",
            button_login:"تسجيل الدخول",
            button_reset_password:"اعادة تعين كلمة السر",
            button_create_account:"إنشاء حساب",
            button_buy_a_package:"شراء رمز تفعيل",
            button_next:"التالي",
            button_previous:"السابق",
            button_reset_password_submet:"إعادة تعين",
            button_horizontal:"أفقي",
            button_vertical:"عمودي",
            button_add:"أضف",

            dropdown_assignToPlaylist:"اضف الى قائمة",
            dropdown_delete:"حذف",

            select_no_playlist_assigned:"بدون قائمة",

            input_device_name:"الاسم",
            input_device_pin:"الرمز",
            input_playlist_name:"الاسم",
            input_login_email:"الإيميل",
            input_login_password:"كلمة السر",
            input_login_remember_me:"حفظ معلومات تسجيل الدخول",
            input_establishment_name:"اسم المنشأة",
            input_activation_code:"رمز التفعيل",
            input_phone_number:"رقم الجوال",
            input_subscription_type:"نوع الاشتراك",
            input_subscription_end_date:"تاريخ الإنتهاء",
            input_establishment_logo:"الشعار",
            input_playlist:"قائمة التشغيل",
            input_image_text:"النص",

            playlist_content:"محتويات القائمة",
            playlist_details:"تفاصيل قائمة التشغيل",
            add_to_a_playlist:"اضف الى قائمة",

            entity_duration:"المدة",
            entity_volume:"الصوت",
            entity_seconds:"ثواني",

            settings_account_section:"الحساب",
            settings_subscription_section:"معلومات الاشتراك",
            settings_whitelabel_section:"شعار المنشاة",

            stats_card_activeScreens:"عدد الشاشات الفعالة",
            stats_card_playlistCount:"عدد وسائط قوائم التشغيل",

            toast_playlist_updated:"تم تحديث قائمة التشغيل",
            toast_updated:"تم الحفظ",
            toast_deleted:"تم الحذف",

            error:"خطأ",
            error_weak_password:"كلمة السر ضعيفة",
            error_establishment_length:"اسم المنشأة اقل من 3 حروف",
            error_activation_code_invalid:"رمز التفعيل غير صحيح",
            error_not_allowed_to_delete:'غير مسموح حذف هذه القيمة',
            error_not_allowed_to_edit:"غير مسموح تعديل هذه القيمة",
            password_or_email_is_incorrect:"الايميل او كلمة السر غير صحيحين",
            error_locked_in_this_tier:"الميزة غير متاحة في هذه الباقة",
            error_email_not_valid:"الإيميل غير صحيح",
            error_device_already_registered:"الجهاز مسجيل مسبقا",
            error_user_profile_not_completed:"قم بإكمال معلومات الحساب",
            error_unsupported_media:"صيغة الملف غير مدعومة"
        }
    }
}