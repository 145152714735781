import { useState } from "react"
import { useTranslation } from "react-i18next"
import { getServerIp } from "../api/apiUtils"



const DragableEntityComponents = (props) => {
    const { t } = useTranslation()
    const [isDetailsOpen, setIsDetailsOpen] = useState(false)
    

    return(
        <div>
            <div className="flex justify-between items-center">
                
                {
                    props.item.entity.entityType === "1"?
                    <img src={props.item.entity.entityFile} width={50} height={50} className="object-contain" alt={props.item.entity.entityTitle || `${props.index}`}/>
                    :
                    <video src={props.item.entity.entityFile} style={{}} className="object-cover" width={'50%'} height={'auto'} controls={false} />
                }


                <div className="flex flex-col items-end justify-around">
                    {/* <div>{props.item.order === null ? "--" : props.item.order}</div> */}

                    <button onClick={() => setIsDetailsOpen((prev) => !prev)} className={`${!isDetailsOpen ? "rotate-180" : ""} hover:cursor-pointer`} >
                        ^
                    </button>
                </div>
            </div>

            <div className={`${!isDetailsOpen ? 'hidden' : '' } mt-5`}>
                <div className={`${props.item.entity.entityType === "1" ? 'flex' : 'hidden'} items-center`}>
                    <div className="text-[10px]" style={{width:'20%'}}>{t('entity_duration')}</div>
                    <div className="border rounded flex items-center px-[2px] grow">
                        <input type={"number"} value={props.item.duration || null} min="1" onChange={(e) => props.onChange(props.item, 'duration', e.target.value)} className="focus:outline-none bg-transparent w-[123.57px]"/>
                        <div className="text-[10px] text-center grow">{t('entity_seconds')}</div>
                    </div>
                </div>
                <div className={`${props.item.entity.entityType === "2" ? 'flex' : 'hidden'} items-center`}>
                    <div className=" text-[10px]" style={{width:'20%'}}>{t('entity_volume')}</div>
                    <div className=" border rounded flex items-center px-[2px] grow justify-between">
                        <input type={"number"} value={props.item.volume || 100} onChange={(e) => props.onChange(props.item, 'volume', e.target.value)} min="0" max="100" className="focus:outline-none bg-transparent w-[123.57px]" />
                        <div className="text-[10px] text-center grow">%</div>
                    </div>
                </div>
                <div className="flex justify-center items-center mt-1">
                    <button className="text-xs text-center text-red-500" onClick={() => props.onRemove(props.item.id)}>{t('button_delete')}</button>
                </div>
            </div>
        </div>
    )
}

export default DragableEntityComponents