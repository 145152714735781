import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";

import Sidebar from "./components/Sidebar";
import LoginPage from "./pages/LoginPage";
import HomePage from './pages/MainPage'
import ResourcesPage from './pages/ResourcesPage';
import PlayersPage from './pages/PlayersPage';
import PlaylistPage from './pages/PlaylistPage';
import PlaylistDetailsPage from './pages/PlaylistDetailsPage';
import NewPlayerPage from './pages/NewPlayerPage';
import NewPlaylistPage from './pages/NewPlaylistPage';
import SignUpPage from './pages/SignUpPage';
import SetupAccountPage from './pages/SetupAccountPage';
import SettingsPage from './pages/SettingsPage';

import './services/i18n/i18n'
import { useTranslation } from 'react-i18next';
import DeviceDetailsPage from './pages/DeviceDetailsPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import MobileSidebar from './components/MobileSidebar';
// import ImageEditorPage from './pages/ImageEditorPage';


function App() {
    const { i18n } = useTranslation();

  return (
    <div dir={i18n.dir()}>
        <BrowserRouter>
            <div className="md:flex lg:flex w-full h-screen">
                <ToastContainer rtl={i18n.dir() === "rtl"} />
                <div id='app-sidebar' className={``}>
                    <div className='hidden md:flex h-full'><Sidebar/></div>
                    <div className='md:hidden'><MobileSidebar /></div>
                </div>
                <div className="pr:3 p-3 md:pr-6 w-full grow overflow-auto">
                    <Routes>
                        <Route path="/" index element={<HomePage />} />
                        <Route path="/setup-account" element={<SetupAccountPage />} />
                        <Route path="/new-playlist" element={<NewPlaylistPage />} />
                        <Route path="/new-player" element={<NewPlayerPage />} />
                        <Route path="/players/:deviceID" element={<DeviceDetailsPage />} />
                        <Route path="/players" element={<PlayersPage />} />
                        <Route path="/resources" element={<ResourcesPage />} />
                        <Route path="/playlists/:playlistID" element={<PlaylistDetailsPage />} />
                        <Route path="/playlists" element={<PlaylistPage />} />
                        <Route path="/signup" element={<SignUpPage />} />
                        <Route path="/reset-password" element={<ResetPasswordPage />} />
                        <Route path="/login" element={<LoginPage />} />
                        {/* <Route path="/image-editor" element={<ImageEditorPage />} /> */}
                        <Route path="/settings" element={<SettingsPage />} />
                        {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
                    </Routes>
                </div>
            </div>
        </BrowserRouter>
    </div>
  );
}

export default App;
