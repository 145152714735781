import { CircleNotch } from "phosphor-react"


const StatsCard = (props) => {


    return(
        <div className="p-2 border max-w-lg shadow w-full">
            <div className="text-center">
                {props.title || ""}
            </div>
            <div hidden={props.isLoading} className="text-center text-4xl font-bold mt-1">{props?.value}</div>
            <div hidden={!props.isLoading} className="animate-spin w-fit m-auto mt-1"><CircleNotch size={32} color="blue"/></div>
        </div>
    )
}

export default StatsCard