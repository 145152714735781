
import { useTranslation } from "react-i18next"
import UserService from "../api/UserService"
import { FloppyDisk, LockKey, SignOut } from "phosphor-react"
import { useRef } from "react"
import { useSettings } from "../hooks/useSettings"

const SettingsPage = () => {
    const logoRef = useRef()
    const { t } = useTranslation()
    const { settings }    = useSettings()


    return(
        <div>
            <div className="flex justify-between items-center">
                <h1 className="font-bold text-2xl py-4">{t('menu_settings')}</h1>
            </div>

            <div>
                <div className="grid gap-y-6 gap-x-8 grid-cols-1 md:grid-cols-2">
                    <div className="w-full p-2 border border-gray-200 shadow-md rounded-lg min-h-[16rem] h-fit">
                        <div className="flex justify-between pb-1">
                            <div className="text-xl font-bold">
                                {t('settings_account_section')}
                            </div>
                            <div className="flex rtl:space-x-reverse space-x-8">
                                <div role={'button'} onClick={() => UserService.logout()} className="flex items-center text-xs text-red-600"><SignOut size={32} /> {t('button_logout')}</div>
                                <div role={'button'} className="" hidden><FloppyDisk size={32} /></div>
                            </div>
                        </div>
                        
                        <div className="w-2/3 m-auto space-y-2">
                            <div className="space-y-1">
                                <div>{t('input_establishment_name')}</div>
                                <input value={settings?.company?.name} className="w-full rounded-lg p-1 disabled:bg-gray-300 text-center" disabled type="text" />
                            </div>
                            <div className="space-y-1">
                                <div>{t('input_login_email')}</div>
                                <input value={settings?.company?.email} className="w-full rounded-lg p-1 disabled:bg-gray-300 text-center" disabled type="text" />
                            </div>
                            <div className="space-y-1">
                                <div>{t('input_phone_number')}</div>
                                <input value={settings?.company?.phoneNumber} className="w-full rounded-lg p-1 disabled:bg-gray-300 text-center" disabled type="text" />
                            </div>
                        </div>
                    </div>
                    <div className="w-full p-2 border border-gray-200 shadow-md rounded-lg min-h-[16rem] h-fit">
                        <div className="flex justify-between pb-1">
                            <div className="text-xl font-bold">
                            {t('settings_subscription_section')}
                            </div>
                            <div role={'button'} className="" hidden><FloppyDisk size={32} /></div>
                        </div>
                        
                        <div className="w-2/3 m-auto space-y-2"> 
                            <div className="space-y-1">
                                <div>{t('input_subscription_type')}</div>
                                <input value={settings?.subscription?.subscription_type} className="w-full rounded-lg p-1 disabled:bg-gray-300 text-center" disabled type="text" />
                            </div>
                            <div className="space-y-1">
                                <div>{t('input_subscription_end_date')}</div>
                                <input value={settings?.subscription?.input_subscription_end_date} className="w-full rounded-lg p-1 disabled:bg-gray-300 text-center" disabled type="text" />
                            </div>
                        </div>
                    </div>
                    <div className="w-full p-2 border border-gray-200 shadow-md rounded-lg min-h-[16rem] relative">
                        <div className={`absolute inset-0 bg-gray-400 opacity-70 rounded-lg cursor-not-allowed ${settings?.brand?.locked ? "block" : 'hidden'}`}>
                            <div className="flex justify-center items-center h-full flex-col">
                                <LockKey size={45} />
                                <div>{t('error_locked_in_this_tier')}</div>
                            </div>
                        </div>
                        
                        <div className="flex justify-between">
                            <div className="text-xl font-bold">
                                {t('settings_whitelabel_section')}
                            </div>
                            <div role={'button'} className="" hidden><FloppyDisk size={32} /></div>
                        </div>
                        
                        <div className="w-2/3 space-y-2 m-auto mt-12"> 
                            <div className="space-y-1 flex justify-center items-center h-full">
                                <input ref={logoRef} className="w-full rounded-lg p-1 disabled:bg-gray-300 disabled:cursor-not-allowed" hidden disabled={settings?.brand?.locked} type="file" />
                                <button disabled={settings?.brand?.locked} onClick={() => logoRef.current.click()} className="w-full bg-gray-200 rounded-lg p-1 disabled:bg-gray-300 disabled:cursor-not-allowed h-16 flex justify-center items-center">
                                    {t('input_establishment_logo')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingsPage