import config from '../config.json'
import { toast } from 'react-toastify'
import i18next from '../services/i18n/i18n'

export const getServerIp = () => {
    if(!config.isProduction){
        return config.devIP
    }
    
    return config.productionIP
}


export const httpHeader = (method, data=null, toJson=false) => {
    const header = {
        method,
        headers: {
        //     "Content-Type": "application/json",
        }
    }

    if(data !== null){
        header.body = toJson ? JSON.stringify(data) : data 
    }

    const token = localStorage.getItem("token")
    if(token != null) { header.headers.Authorization = `Token ${token}`}
    
    return header
}

export const getHttpGetHeader = () => {
    const header = {
        method:"GET",
        headers: {
            "Content-Type": "application/json",
        }
    }

    const token = localStorage.getItem("token")
    if(token != null) { header.headers.Authorization = `Token ${token}`}
    
    return header
}

export const getHttpPostFormDataHeader = (data) => {
    const header = {
        method:"POST",
        headers: {
            // "Content-Type": "multipart/form-data",
        },
        body: data
    }

    const token = localStorage.getItem("token")
    if(token != null) { header.headers.Authorization = `Token ${token}`}
    
    return header
}

export const getHttpPostHeader = (data) => {
    const header = {
        method:"POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    }

    const token = localStorage.getItem("token")
    if(token != null) { header.headers.Authorization = `Token ${token}`}
    
    return header
}

export const getHttpPatchHeader = (data) => {
    const header = {
        method:"PATCH",
        headers: {
            "Content-Type": "application/json"
        },
        body:JSON.stringify(data)
    }

    const token = localStorage.getItem("token")
    if(token != null) { header.headers.Authorization = `Token ${token}`}
    
    return header
}

export const getHttpUpdateHeader = (data) => {
    const header = {
        method:"PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body:JSON.stringify(data)
    }

    const token = localStorage.getItem("token")
    if(token != null) { header.headers.Authorization = `Token ${token}`}
    
    return header
}

export const getHttpDistoryHeader = () => {
    const header = {
        method:"DELETE",
        headers: {
            "Content-Type": "application/json"
        }
    }

    const token = localStorage.getItem("token")
    if(token != null) { header.headers.Authorization = `Token ${token}`}
    
    return header
}

export const getHttpPostFilesHeader = (data) => {
    const header = {
        method:"POST",
        headers: {
            // "Content-Type": "application/json"
        },
        body:data
    }

    const token = localStorage.getItem("token")
    if(token != null) { header.headers.Authorization = `Token ${token}`}

    return header
}

export  const getPrefixUrl = () => {
    return getServerIp()
}


export const toastSuccess = (msg) => {
    toast.success(
        msg
    ) 
}

export const toastObjectUpdated = (msg) => {
    toast.success(
        msg
    ) 
}

export const toastError = (msg) => {
    toast.error(
        i18next.t(msg),
        {
            position:'top-center'
        }
    ) 
}