import { getHttpDistoryHeader, getHttpGetHeader, getHttpPatchHeader, getHttpPostHeader, getHttpUpdateHeader, getServerIp, toastError, toastSuccess } from "./apiUtils"
import i18next from '../services/i18n/i18n'
import { request } from "./RequestManager"

const BASE_PATH = 'playlist-service'


const all = async (page=1, limit=8) => {
    const url = `${getServerIp()}api/cv/${BASE_PATH}/?${new URLSearchParams({ limit, page })}`
    const header = getHttpGetHeader()
    
    try {
        const req    = await request(url, header)

        if(req.status === 200){
            return await req.json()
        }
        else if(req.status === 403){
            window.location.href="/login"
        }

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}


const get = async (id) => {
    const url = `${getServerIp()}api/cv/${BASE_PATH}/${id}`
    const header = getHttpGetHeader()
    
    try {
        const req    = await request(url, header)

        if(req.status === 200){
            return await req.json()
        }
        else if(req.status === 403){
            window.location.href="/login"
        }

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}

const create = async (data) => {
    const url = `${getServerIp()}api/cv/${BASE_PATH}/`
    const header = getHttpPostHeader(data)
    
    try {
        const req    = await request(url, header)

        if(req.status === 201){
            return await req.json()
        }
        else if(req.status === 403){
            window.location.href="/login"
        }

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}


const patch = async (id, data) => {
    const url = `${getServerIp()}api/cv/${BASE_PATH}/${id}/`
    const header = getHttpPatchHeader(data)
    
    try {
        const req    = await request(url, header)
        
        if(req.status === 200){
            return await req.json()
        }
        else if(req.status === 403){
            window.location.href="/login"
        }

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}

const update = async (id, data) => {
    const url = `${getServerIp()}api/cv/${BASE_PATH}/${id}/`
    const header = getHttpUpdateHeader(data)
    
    try {
        const req    = await request(url, header)
        
        if(req.status === 200){
            return await req.json()
        }
        else if(req.status === 403){
            window.location.href="/login"
        }
        
        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}

const distroy = async (id) => {
    const url = `${getServerIp()}api/cv/${BASE_PATH}/${id}/`
    const header = getHttpDistoryHeader()
    
    try {
        const req    = await request(url, header)
        
        if(req.status === 204){
            
            return true
        }
        else if(req.status === 405){
            toastError(i18next.t('error_not_allowed_to_delete'))
            return true
        }
        else if(req.status === 403){
            window.location.href="/login"
        }

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}

const removeItemFromPlaylist = async (id) => {
    const url = `${getServerIp()}api/cv/${BASE_PATH}/remove-entity-from-playlist/${id}/`
    const header = getHttpDistoryHeader()
    
    try {
        const req    = await request(url, header)
        
        if(req.status === 204){
            toastSuccess(i18next.t('toast_deleted'))
            return true
        }
        else if(req.status === 405){
            toastSuccess(i18next.t('error_not_allowed_to_edit'))
            return true
        }
        else if(req.status === 403){
            window.location.href="/login"
        }

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}

const updatePlaylist = async (data) => {
    const url = `${getServerIp()}api/cv/${BASE_PATH}/update-playlist-order/`
    const header = getHttpPostHeader(data)
    
    try {
        const req    = await request(url, header)

        if(req.status === 202){
            return await req.json()
        }
        else if(req.status === 403){
            window.location.href="/login"
        }

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}

const addEntityToPlaylist = async (data) => {
    const url = `${getServerIp()}api/cv/${BASE_PATH}/add-entity/`
    const header = getHttpPostHeader(data)
    
    try {
        const req    = await request(url, header)

        if(req.status === 201){
            return await req.json()
        }
        else if(req.status === 403){
            window.location.href="/login"
        }

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}

const PlaylistService = {
    all, get, create, patch, distroy, update, updatePlaylist, addEntityToPlaylist, removeItemFromPlaylist
}

export default PlaylistService















