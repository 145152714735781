import { getServerIp } from "../api/apiUtils"



const PlaylistItemComponent = (props) => {
    return (
        <div className="flex items-center justify-center">
            {
                props.item.entity.entityType === "1" ?
                    <img loading="lazy" src={props.item.entity.entityFile} width={'70%'} height={'auto'} className="object-contain max-w-[200px]" alt={props.item.entity.entityTitle || `${props.index}`} />
                    :
                    <video src={props.item.entity.entityFile} style={{}} className="object-cover" width={'70%'} height={'auto'} controls={false} />
            }
        </div>
    )
}

export default PlaylistItemComponent