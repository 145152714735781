import { ArrowSquareOut, CircleNotch } from "phosphor-react"
import { useState } from "react"
import UserService from "../api/UserService"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"


const MAX_STEPS = 3


const SetupAccountPage = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [step, setStep] = useState(1)
    const [name, setName] = useState("")
    const [activationCode, setActivationCode] = useState("")
    const [errorMsg, setErrorMsg] = useState("")

    const nextButton = () => {
        if(step === MAX_STEPS) { submit() }
        else if(step + 1 <= MAX_STEPS){
            setStep(step + 1)
        }
    }

    const prevButton = () => {
        if(step - 1 > 0){
            setStep((prev) => prev - 1)
        }
    }

    const validate = () => {
        setErrorMsg("")
        if(name.length < 3){
            setErrorMsg(t('error_establishment_length'))
            return false
        }
        else if(activationCode.length < 3){
            setErrorMsg(t('error_activation_code_invalid'))
            return false
        }

        return true
    }

    const submit = () => {
        if(!validate()) { return }
        setIsLoading(true)
        UserService.completeProfile({name, activationCode})
        .then((res) => {
            navigate('/')
        })
        .catch((e) => setErrorMsg(e?.toString()))
        .finally(() => setIsLoading(false))
    }

    return(
        <div className="max-w-lg m-auto mt-20">
            <div className="flex justify-center items-start rtl:space-x-reverse space-x-3 mt-4 mb-8 ">
                <div className={`w-6 h-6 rounded-full ${step >= 1 ? 'bg-green-500' : 'bg-black'}`} />
                <div className={`w-6 h-6 rounded-full ${step >= 2 ? 'bg-green-500' : 'bg-black'}`} />
                <div className={`w-6 h-6 rounded-full ${step >= 3 ? 'bg-green-500' : 'bg-black'}`} />
            </div>

            <div hidden={!isLoading} className="animate-spin w-fit m-auto"><CircleNotch size={32} color="blue"/></div>

            <div hidden={isLoading}>
                <div className={`${step === 1 ? 'block': 'hidden'}`}>
                    <div className="font-bold">{t('menu_account_info')}</div>

                    <div className="mt-4">
                        <div className="mb-4">
                            <div>{t('input_establishment_name')}</div>
                            <input value={name} onChange={(e) => setName(e.target.value)} maxLength="50" className="focus:outline-none px-2 w-full border border-black p-2 rounded" />
                        </div>
                    </div>
                </div>

                <div className={`${step === 2 ? 'block': 'hidden'} p-2`}>
                    <div className="font-bold">{t('subscription_packages')}</div>

                    <div className="mt-4 space-y-3">
                        <div className="p-2 border rounded-lg">
                            <div>البداية</div>
                            <div className="px-10">
                                <ul className="list-disc">
                                    <li>1-4 شاشات</li>
                                </ul>
                            </div>
                            

                            <div className="flex justify-end">
                                <div>
                                    75-771 {t('sar')}
                                </div>
                            </div>
                        </div>
                        
                        <div className="p-2 border rounded-lg">
                            <div>النمو</div>
                            <div className="px-10">
                                <ul className="list-disc">
                                    <li>1-8 شاشات</li>
                                </ul>
                            </div>
                            

                            <div className="flex justify-end">
                                <div>
                                    135-1388 {t('sar')}
                                </div>
                            </div>
                        </div>

                        <div className="p-2 border rounded-lg">
                            <div>المتقدمة</div>
                            <div className="px-10">
                                <ul className="list-disc">
                                    <li>+9 شاشات</li>
                                </ul>
                            </div>
                            

                            <div className="flex justify-end">
                                <div>
                                    135-1388 {t('sar')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={`${step === 3 ? 'block': 'hidden'} p-2`}>
                    <div className="font-bold">{t('activate_a_package')}</div>
                    <div className="mt-4">
                        <div className="mb-4">
                            <div>{t('input_activation_code')}</div>
                            <input value={activationCode} onChange={(e) => setActivationCode(e.target.value)} className="focus:outline-none px-2 w-full border border-black p-2 rounded" />
                        </div>
                    </div>

                    <div>
                        <a href="https://signage.sensor.sa/" target="_blank" rel="noreferrer" className="border py-1 px-5 rounded w-fit m-auto flex items-center rtl:space-x-reverse space-x-2 select-none">
                            <ArrowSquareOut />
                            <div>{t('button_buy_a_package')}</div>
                        </a>
                    </div>
                </div>
            </div>    

            <div className="text-center text-red-500"> 
                {errorMsg}
            </div>

            <div className="flex justify-end rtl:space-x-reverse space-x-4">
                <button disabled={isLoading} onClick={() => prevButton()}>{t('button_previous')}</button>
                <button disabled={isLoading} onClick={() => nextButton()}>{t('button_next')}</button>
            </div>
        </div>
    )
}

export default SetupAccountPage