import { CircleNotch } from "phosphor-react"
import { useState } from "react"
import { useRef } from "react"
import EntityService from "../api/EntityService"
import { useTranslation } from "react-i18next"



const ResourcesUploadBox = (props) => {
    const { t } = useTranslation()
    const resourcesInput = useRef()
    const [uploadFile, setUploadFile] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")


    const upload = (file) => {
        if(file === undefined) { return }
        setErrorMsg("")
        setIsLoading(true)

        const data = new FormData()
        data.append('entityFile', file)
        if(file.name.includes('svg')){
            setIsLoading(false) 
            resourcesInput.current.value = ""
            return setErrorMsg("error_unsupported_media")
        }

        const fileExt = file?.type?.split("/")[0] || ""

        if(fileExt === "video"){
            data.append('entityType', '2')
        }
        else if(fileExt === "image"){
            // data.append('entityType', 'IMAGE')
            data.append('entityType', '1')
        }
        else{
            setIsLoading(false)
            return setErrorMsg("error_unsupported_media")
        }
        

        EntityService.create(data)
        .then((res) => props.append(res))
        .catch((e) => console.error(e))
        .finally(() => setIsLoading(false))
    }

    return(
        <div className="bg-gray-300 h-24 w-full rounded p-2 mb-6 flex justify-center items-center flex-col text-center border border-black border-dashed select-none">
            <p hidden={isLoading} onClick={() => resourcesInput.current.click()} className=" px-4">{t('button_uploadfile')}</p>
            <p hidden={errorMsg === ""} className="text-red-500 font-bold select-none">{t(errorMsg)}</p>
            <div hidden={!isLoading}>
                <CircleNotch color="blue" size={24} className="animate-spin" />
            </div>
            <input hidden ref={resourcesInput} type="file" accept="image/*,video/*"  onChange={(e) => upload(e.target.files[0] || undefined)} />
        </div>
    )
}


export default ResourcesUploadBox