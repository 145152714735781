import { CircleNotch } from "phosphor-react"
import { useEffect, useState } from "react"
import Select from 'react-select'
import DevicesService from "../api/DevicesService"
import PlaylistService from "../api/PlaylistService"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { toastError } from "../api/apiUtils"




const PlayersPage = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [players, setPlayers] = useState([])
    const [isLoading, setIsloading] = useState(!false)
    const [playlists, setPlaylists] = useState([])
    const [isPlaylistLoading, setIsPlaylistLoading] = useState(false)

    const init = () => {
        setIsloading(true)
        DevicesService.all(1, 500)
        .then((res) => setPlayers(res || []))
        .catch((e) => console.error(e))
        .finally(() => setIsloading(false))
        
        setIsPlaylistLoading(true)
        PlaylistService.all(1, 99)
        .then((res) => setPlaylists(res || []))
        .catch((e) => console.error(e))
        .finally(() => setIsPlaylistLoading(false))
    }

    useEffect(() => {
        init()
    }, [])

    const onAssignPlaylistToDeivce = (deviceID, playlistID) => {
        console.log(playlistID)
        setIsPlaylistLoading(true)
        DevicesService.assignPlaylist(deviceID, playlistID)
        .then((res) => {
            setPlayers((prev) => {
                return prev.map((x) => {
                    if(x.id === res.id){ x = res }
                    return x
                })
            })
        })
        .catch((e) => toastError(e.message))
        .finally(() => setIsPlaylistLoading(false))
    }

    return(
        <div>
            <div className="flex justify-between items-center">
                <h1 className="font-bold text-2xl py-4">{t('menu_devices')}</h1>
                <button onClick={() => navigate('/new-player')}>{t('button_newDevice')}</button>
            </div>


            <div hidden={!isLoading} className="animate-spin w-fit m-auto"><CircleNotch size={32} color="blue"/></div>
            <div className={`${players.length === 0 ? 'hidden' : 'block'} space-y-4`}>
                {
                    players.map((item) => (
                        <div key={item.id} className="border border-black p-1 rounded-lg flex flex-col">
                            <div className="flex w-full items-center justify-between py-0.5 px-1">
                                    <Link className="grow" to={`/players/${item.id}`} state={{...item}} >
                                        <div className="text-sm md:text-lg w-full">
                                            {item.name}
                                        </div>
                                    </Link>

                                <Select isLoading={isPlaylistLoading} value={item.activePlaylist} className="w-fit" onChange={(newValue) => onAssignPlaylistToDeivce(item.id, newValue.id)} options={[{id:undefined, name:t('select_no_playlist_assigned')}, ...playlists]}  getOptionValue={(option) => `${option['id']}`} getOptionLabel={(option) => `${option.name}`} placeholder={t('select_no_playlist_assigned')} />                                    
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default PlayersPage