import { Gear, House, NewspaperClipping, Storefront } from "phosphor-react"
import { request } from "./RequestManager"
import { getHttpGetHeader, getHttpPostHeader, getServerIp } from "./apiUtils"

const BASE_PATH = 'rest-auth'


const sidebarItems = [
    {
        title : 'menu_homepage',
        icon  : <House />,
        link  : '/',
        subs  : [
            // {
            //     title : "الشاشة الرئيسية",
            //     icon  : undefined,
            //     link  : ''
            // }
        ]
    },
    {
        title : 'menu_resources',
        icon  : <NewspaperClipping />,
        link  : '/resources',
        subs  : []
    },
    {
        title : 'menu_devices',
        icon  : <Storefront />,
        link  : '/players',
        subs  : []
    },
    {
        title : 'menu_playlists',
        icon  : <Storefront />,
        link  : '/playlists',
        subs  : []
    },
    // {
    //     title : 'menu_image_editor',
    //     icon  : <Image  />,
    //     link  : '/image-editor',
    //     subs  : []
    // },
    {
        title : 'menu_settings',
        icon  : <Gear  />,
        link  : '/settings',
        subs  : []
    },
]

const me = async () => {
    const url = `${getServerIp()}rest-auth/user/`
    const header = getHttpGetHeader()
    
    try {
        const req    = await request(url, header)
        const body   = await req.json()

        if(req.status === 200){
            return body
        }
        else if(req.status === 403){
            window.location.href="/login"
        }

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}

const getSettings = async () => {
    const url = `${getServerIp()}api/settings`
    const header = getHttpGetHeader()
    
    try {
        const req    = await request(url, header)
        const body   = await req.json()

        if(req.status === 200){
            return body
        }
        else if(req.status === 403){
            window.location.href="/login"
        }
        else if(body?.error){
            throw Error(body.error)
        }

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}

const getHome = async () => {
    const url = `${getServerIp()}api/home`
    const header = getHttpGetHeader()
    
    try {
        const req    = await request(url, header)
        const body   = await req.json()

        if(req.status === 200){
            return body
        }
        else if(body?.error){
            throw Error(body.error)
        }

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}

const login = async (username, password) => {
    const url = `${getServerIp()}rest-auth/login/`
    const header ={
        method:"POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({username, password})
    }
    
    try {
        const req    = await request(url, header)
        const body   = await req.json()

        if(req.status === 200){
            localStorage.setItem('token', body.key)
            window.location.href="/"
            // setTimeout(() => localStorage.setItem("token", body.key), 400)
            
            return body
        }

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}

const logout = async () => {
    const url = `${getServerIp()}rest-auth/logout/`
    const header = getHttpPostHeader()
    
    try {
        const req    = await request(url, header)
        
        if(req.status === 200){
            localStorage.removeItem("token")
            window.location.href="/login"
        }

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}

const signup = async (data) => {
    const url = `${getServerIp()}${BASE_PATH}/registration/`
    const header = getHttpPostHeader(data)
    
    try {
        const req    = await request(url, header)
        const body   = await req.json()
        
        if(req.status === 201){
            localStorage.setItem("token", body.key)
            return [true, body]
        }
        else if(req.status === 400){
            return [false, body]
        }
       

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}

const refreshMeInfo = async () => {
    try {
       const token = localStorage.getItem("token")
       if(token === undefined){return}

       const profile = await me()
       
        for (const key in profile.workingAt) {
            const element = profile.workingAt[key] || undefined
            
            if(element !== undefined){
                localStorage.setItem(key, element)
            }

        }

        return profile
    } 
    catch (error) {
        console.error('error while updating localstorage ', error);
    }
}

const completeProfile = async (data) => {
    const url = `${getServerIp()}api/complete-profile`
    const header = getHttpPostHeader(data)
    
    try {
        const req    = await request(url, header)
        const body   = await req.json()
        
        if(req.status === 201){
            return body
        }
        else if(body?.error){
            throw Error(body.error)
        }
        


        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}

const removeToken = () => {
    localStorage.removeItem("token")
}

const resetPassword = async (data) => {
    const url = `${getServerIp()}rest-auth/password/reset/`
    const header = getHttpPostHeader(data)
    
    try {
        const req    = await request(url, header)
        const body   = await req.json()
        
        if(req.status === 200){
            return body
        }
        else if(body?.email){
            throw Error(body?.email)
        }

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}

const getSidebarItems = async () => {
    return sidebarItems
}

const UserService = {
    login, 
    logout, 
    me, 
    refreshMeInfo, 
    signup, 
    completeProfile, 
    getSettings, 
    getHome, 
    removeToken,
    resetPassword,
    getSidebarItems
}

export default UserService