import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import UserService from "../api/UserService"
import config from '../config.json'
import { useState } from "react"
import { useTranslation } from "react-i18next"

const LoginPage = () => {
    const { t, i18n } = useTranslation()
    const navigate =  useNavigate()
    const { register, handleSubmit, reset } = useForm()
    const [isLoading, setIsloading] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")

    const onSubmit = (data) => {
        setErrorMsg("")
        setIsloading(true)
        UserService.login(data.username, data.password)
        .then(() => {
            reset()
        })
        .catch((e) => setErrorMsg(t('password_or_email_is_incorrect')))
        .finally(() => setIsloading(false))
    }

    return(
        <div className="h-full grid place-items-center">
            <div className="w-96 rounded-lg border border-gray-300 shadow-md p-2 lg:p-6">
                <div className="my-6">
                    <h5 className="text-center text-xl font-medium text-gray-900">{config.ProjectName}</h5>
                </div>


                <form className="space-y-10x" onSubmit={handleSubmit(onSubmit)}>
                    <div className="">
                        <label htmlFor="email" className="block mb-1 text-sm font-medium text-gray-900">{t('input_login_email')}</label>
                        <input dir="auto" {...register("username", {required: true})} id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required />
                    </div>
                    <div className="mt-3">
                        <label htmlFor="password" className="block mb-1 text-sm font-medium text-gray-900">{t('input_login_password')}</label>
                        <input dir="auto" {...register("password", {required: true})} type="password" id="password" placeholder="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                    </div>

                    <div className="mt-1 mb-4">
                        <div className="items-start rtl:space-x-reverse space-x-1 hidden -flex">
                            <input id="remember" type="checkbox" value="" className="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300" />
                            <label htmlFor="remember" className="ml-2 text-sm font-medium text-gray-900">{t('input_login_remember_me')}</label>
                        </div>

                        <Link to="/reset-password">
                            <div className="ml-auto text-xs text-blue-700 cursor-pointer select-none hover:underline">{t('button_reset_password')}</div>
                        </Link>
                        <div className="text-xs text-red-700">
                            {/* <div>{errors.username}</div>
                            <div>{errors.password}</div> */}
                            {errorMsg}
                        </div>
                    </div>

                    
                    <div role={"button"} onClick={() => navigate('/signup')} className="text-center underline text-blue-700 select-none">
                        {t('button_create_account')}
                    </div>

                    <button disabled={isLoading} type="submit" className="w-full mt-8 text-white bg-blue-700 hover:bg-blue-800 disabled:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">{t('button_login')}</button>
                </form>

                <div className='flex justify-center items-center rtl:space-x-reverse space-x-3 mt-4'>
                    <button className='' onClick={() => i18n.changeLanguage('en')}>EN</button>
                    <div>/</div>
                    <button className='' onClick={() => i18n.changeLanguage('ar')}>AR</button>
                </div>
            </div>
        </div>
    )
}

export default LoginPage