import { CheckCircle, CircleNotch } from "phosphor-react"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import UserService from "../api/UserService"



const ResetPasswordPage = () => {
    const { t } = useTranslation()
    const { register, handleSubmit, reset, formState: { errors },  } = useForm({shouldUseNativeValidation:true})
    const [error, setError] = useState("")
    const [isLoading, setIsloading] = useState(false)
    const [resetFormStep, setResetFormStep] = useState(0)

    const onSubmit = (data) => {
        setIsloading(true)
        setError("")
        
        UserService.resetPassword(data)
        .then((res) => {
            setResetFormStep(1)
        })
        .catch((e) => {
            console.error(e.message)
            setError(t('error_email_not_valid'))
        })
        .finally(() => setIsloading(false))
    } 

    return (
        <div className="flex justify-center items-center h-full flex-col">
            <div className="h-20 md:hidden"/>
            <div className="p-2 border max-w-xl w-full mb-10">
                <div className="flex justify-between items-center">
                    <div className="border-b pb-2">{t('section_reset_password')}</div>
                    <Link to={'/login'}>
                        <div className="underline text-blue-600 text-smx">{t('button_login')}</div>
                    </Link>
                </div>
                <div hidden={!isLoading} className="animate-spin w-fit m-auto mt-6"><CircleNotch size={32} color="blue"/></div>
                
                <div className={`${resetFormStep === 0 ? "block" : "hidden"}`}>
                    <form className={`${(isLoading) ? "hidden" : "block"}`} onSubmit={handleSubmit(onSubmit)}>
                        <dir className="p-2">
                            <div>{t('input_login_email')}</div>
                            <input dir="auto" className="focus:outline-none px-2 w-full border border-black p-2 rounded" type="email" {...register("email", {required: true, maxLength: 100})} />
                            <div className={`text-red-500 font-bold select-none text-left ${errors?.name ? "block" : "hidden"}`}>X</div>
                        </dir>

                        <div className="flex justify-center">
                            <button className="px-4 text-sm bg-blue-600 py-1 rounded text-white" type="submit">{t('button_reset_password_submet')}</button>
                        </div>
                    </form>
                </div>

                <div hidden={resetFormStep === 0}>
                    <div className="flex justify-center pb-1 pt-2"><CheckCircle size={48} color="#0008fa" /></div>
                    <div className="text-center ">Password Reset</div>
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordPage