import { useState } from "react"
import UserService from "../api/UserService"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import config from '../config.json'
import { useTranslation } from "react-i18next"








const SignUpPage = () => {
    const { t } = useTranslation()
    const navigate =  useNavigate()
    const { register, handleSubmit, reset, formState: { errors }, setError } = useForm({shouldUseNativeValidation: true})
    const [isLoading, setIsloading] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")

    const onSubmit = (data) => {
        setErrorMsg("")
        setIsloading(true)
        UserService.signup({username:data.username, password1:data.password1, password2:data.password1})
        .then(([isValid, body]) => {
            if(isValid){
                reset()
                return navigate('/')
            }
            for (const key in body) {
                let serverErrors = []
                if (Object.hasOwnProperty.call(body[key], 'length')) {
                    serverErrors = serverErrors.concat(body[key])
                }
                setErrorMsg(serverErrors.join('\n'))
            }
        })
        .catch((e) => {
            setErrorMsg(t('error'))
            console.error(e.message)

        })
        .finally(() => setIsloading(false))
    }

    return(
        <div className="h-full grid place-items-center">
            <div className="w-96 rounded-lg border border-gray-300 shadow-md p-2 lg:p-6">
                <div className="my-6">
                    <h5 className="text-center text-xl font-medium text-gray-900" onClick={() => {setError('password1', { type: 'custom', message: 'custom message' }); console.log(errors?.serverError?.message.toString())}}>{config.ProjectName}</h5>
                </div>

                <form className="space-y-10x" onSubmit={handleSubmit(onSubmit)}>
                    <div className="">
                        <label htmlFor="email" className="block mb-1 text-sm font-medium text-gray-900">{t('input_login_email')}</label>
                        <input dir="auto" {...register("username", {required: true, pattern: /^\S+@\S+$/i})} id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 invalid:border-red-500" placeholder="" required />
                    </div>
                    <div className="mt-3">
                        <label htmlFor="password" className="block mb-1 text-sm font-medium text-gray-900">{t('input_login_password')}</label>
                        <input dir="auto" {...register("password1", {required: true, minLength:8})} type="password" id="password" placeholder="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                    </div>
                    <div className="mt-3">
                        <label htmlFor="phoneNumber" className="block mb-1 text-sm font-medium text-gray-900">{t('input_phone_number')}</label>
                        <input dir="auto" {...register("phoneNumber", {required: true, minLength:8, pattern:"^(009665|9665|+9665)(5|0|3|6|4|9|1|8|7)([0-9]{7})$"})} type="tel" id="phoneNumber" placeholder="" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                    </div>

                    <div className="mt-1 ">
                        <p className="whitespace-pre-wrap text-xs text-red-700">
                            {t(errorMsg)}
                        </p> 
                    </div>
                    

                    <button disabledx={isLoading} type="submit" className="w-full mt-4 mb-4 text-white bg-blue-700 hover:bg-blue-800 disabled:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">{t('button_create_account')}</button>
                    <div role={"button"} onClick={() => navigate('/login')} className="text-center text-xs text-blue-700 select-none">
                        {t('button_login')}
                    </div>
                </form>
            </div>
        </div>
    )
}



export default SignUpPage