import middlewareService from "./middlewares"


let before = []
let after  = [middlewareService.loginStatus, middlewareService.profileStatus]



export const request = async (url, header, callback, additionalBeforeMiddlewares=[], additionalAfterMiddlewares=[]) => {
   
    try {
        const beforeMiddlewares = [...before].concat(before)
        const afterMiddlewares  = [...after ].concat(after)


        for (let i=0; i<beforeMiddlewares.length; i++) {
            await beforeMiddlewares[i](url, header);
        }
    
        let res = await fetch(url, header);

        for (let i=0; i<afterMiddlewares.length; i++) {
            await afterMiddlewares[i](url, header, res)
        }
        
        return res;    
    } catch (error) {
        console.error(error)
    }
}