import { useEffect } from "react"
import { useState } from "react"
import PlaylistService from "../api/PlaylistService"



export const useSelectedPlaylist = () => {
    const [isAssignToPlaylistOpen, setIsAssignToPlaylistOpen] = useState(false)
    const [isSelectPlaylistModalLoading, setIsSelectPlaylistModalLoading] = useState(true)
    const [playlists, setPlaylists] = useState([])
    const [errorMsg, setErrorMsg] = useState("")
    const [selectedEntity, setSelectedEntity] = useState()
    
    useEffect(() => {
        getAvaliabePlaylists()
    }, [])


    const addEntityToPlaylist = (playlist) => {
        PlaylistService.addEntityToPlaylist({playlistID: playlist.id, entityID:selectedEntity.id})
        .then((res) => {
            
            closeModal()
        })
        .catch((e) => {
            console.error(e)
            setErrorMsg(e.message)
        })
    }

    const getAvaliabePlaylists = async () => {
        setIsSelectPlaylistModalLoading(true)
        PlaylistService.all(1, 100)
        .then((res) => setPlaylists(res || []))
        .catch((e) => console.error(e))
        .finally(() => setIsSelectPlaylistModalLoading(false))
    }


    const openModal = (entity) => {
        setSelectedEntity(entity)
        setIsAssignToPlaylistOpen(true)
    }
    const closeModal = () => {
        setSelectedEntity(undefined)
        setIsAssignToPlaylistOpen(false)
    }   

    return {
        state:{
            playlists,
            isAddModalOpen:isAssignToPlaylistOpen, 
            isLoading:isSelectPlaylistModalLoading, 
            errorMsg 
        },
        action:{
            addEntityToPlaylist, 
            setIsAssignToPlaylistOpen,
            closeAddModal: closeModal, 
            openAddModal: openModal, 
        } 
    }
}