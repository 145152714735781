import { useState } from "react"
import { useNavigate } from "react-router-dom"
import DevicesService from '../api/DevicesService'
import { CircleNotch } from "phosphor-react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"



const NewPlayerPage = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [isLoading, setIsloading] = useState(false)
    const [error, setError] = useState("")
    const { register, handleSubmit, reset, formState: { errors },  } = useForm()
    
    const onSubmit = (data) => {
        setIsloading(true)
        setError("")
        DevicesService.create(data)
        .then(() => {
            reset()
            navigate('/players')
        })
        .catch((e) => setError(e.message))
        .finally(() => setIsloading(false))
    } 

    return(
        <div>
            <div className="flex justify-between items-center">
                <h1 className="font-bold text-2xl py-4">{t('menu_new_device')}</h1>
                <button type="submit" form="new-device-from">{t('button_save')}</button>
            </div>
            <div hidden={!isLoading} className="animate-spin w-fit m-auto"><CircleNotch size={32} color="blue"/></div>
            <div className="text-red-500 text-center">{error?.toString()}</div>

            <form  onSubmit={handleSubmit(onSubmit)} id="new-device-from">
                <dir className="p-2">
                    <div>{t('input_device_name')}</div>
                    <input dir="auto" className="focus:outline-none px-2 w-full border border-black p-2 rounded" type="text" {...register("name", {required: true, maxLength: 100})} />
                    <div className={`text-red-500 font-bold select-none text-left ${errors?.name ? "block" : "hidden"}`}>X</div>
                </dir>
                <dir className="p-2">
                    <div>{t('input_device_pin')}</div>
                    <input dir="auto" className="focus:outline-none px-2 w-full border border-black p-2 rounded" type="text" {...register("pin", {required: true, maxLength: 50})} />
                    <div className={`text-red-500 font-bold select-none text-left ${errors?.pin ? "block" : "hidden"}`}>X</div>
                </dir>
            </form>
        </div>
    )
}

export default NewPlayerPage