import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import DevicesService from "../api/DevicesService"
import { useEffect } from "react"
import { CircleNotch } from "phosphor-react"
import PlaylistItemComponent from "../components/PlaylistItemComponent"
import { toastError, toastObjectUpdated } from "../api/apiUtils"
import usePlaylists from "../hooks/usePlaylists"
import Select from 'react-select'


const DeviceDetailsPage = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const urlParams = useParams()
    const [data, setData] = useState()
    const [isLoading, setIsloading] = useState(true)
    const usePlaylistsService = usePlaylists()

    const init = () => {
        setIsloading(true)
        DevicesService.get(urlParams.deviceID)
        .then((res) => {setData(res)})
        .catch((e) => console.error(e))
        .finally(() => setIsloading(false))
    }

    
    useEffect(() => {
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onDeleteDevice = () => {
        DevicesService.distroy(urlParams.deviceID)
        .then((res) => navigate('/players'))
        .catch((e) => toastError(e.message))
    }

    const save = () => {
        DevicesService.update(data.id, {name:data.name})
        .then((res) => {
            setData(Object.assign({}, res))
            toastObjectUpdated(t('toast_updated'))
        })
        .catch((e) => {
            console.error(e)
            toastError(e.message)
        })
    }

    const onAssignPlaylistToDeivce = (deviceID, playlistID) => {
        console.log(playlistID)
        setIsloading(true)
        DevicesService.assignPlaylist(deviceID, playlistID)
        .then((res) => {
            setData((prev) => {
               return res
            })
        })
        .catch((e) => toastError(e.message))
        .finally(() => setIsloading(false))
    }

    return(
        <div>
            <div className="flex justify-between items-center">
                <h1 className="font-bold text-2xl py-4">{data?.name || t('menu_device_details')}</h1>
                <button onClick={() => save()}>{t('button_save')}</button>
            </div>
            
            <div hidden={!isLoading} className="animate-spin w-fit m-auto"><CircleNotch size={32} color="blue"/></div>
            <div className="w-full">
                <div className="p-2 my-4 m-auto max-w-xl">
                    <div className="space-y-1 mb-2">
                        <div>{t('input_playlist_name')}</div>
                        <input value={data?.name} onChange={(e) => setData((prev) => Object.assign({},prev, {name:e.target.value}) )} className="border p-2 rounded-md focus:outline-none w-full"/>
                    </div>

                    <div className="space-y-1 mb-2">
                        <div>{t('input_playlist')}</div>
                        <Select isLoading={usePlaylistsService.isUsePlaylistsLoading} value={data?.activePlaylist}  className="w-full" onChange={(newValue) => onAssignPlaylistToDeivce(data.id, newValue.id)} options={[{id:undefined, name:t('select_no_playlist_assigned')}, ...usePlaylistsService.playlists]}  getOptionValue={(option) => `${option['id']}`} getOptionLabel={(option) => `${option.name}`} placeholder={t('select_no_playlist_assigned')} />
                    </div>

                    <div className="p-2 border rounded-md h-44 flex flex-col mt-4">
                        <div className="mb-2">
                            {t('section_playlist')}
                        </div>

                        <div className={`grow ${data?.activePlaylist?.content?.length > 0 ? 'hidden' : 'flex'} flex-col items-center justify-center`}>
                            <div className="text-lg">NO_PLAYLIST</div>
                            <div className="text-sm">NO_PLAYLIST_SUB_TITLE</div>
                        </div>


                        <div className="overflow-x-auto flex pb-2">
                            {
                                data?.activePlaylist?.content?.map((playlistItem, index) => (
                                    <div className="bg-blue-500x flex-wrap basis-40x min-w-[10rem] flex items-center justify-center p-1 mx-2 border-[1px]">
                                        <PlaylistItemComponent item={playlistItem} index={index}/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    
                    <div className="flex justify-center items-center pt-10">
                        <button onClick={() => onDeleteDevice()} className="text-center text-red-500">
                            {t('button_delete')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeviceDetailsPage