import { House, NewspaperClipping, Storefront, Gear, SignOut, Image } from 'phosphor-react';
import {useState, useEffect} from 'react';
import { useLocation, Link } from "react-router-dom";
import config from '../config.json'
import { useTranslation } from 'react-i18next'
import sidebarLogo from '../images/sidebar-logo.png'
import UserService from '../api/UserService';



const Sidebar = () => {
    const { t, i18n } = useTranslation()
    const location = useLocation()
    const [pathName, setPathName] = useState("")
    const [sidebarItems, setSidebarItems] = useState([])
    
    useEffect(() =>{
        setPathName(location.pathname)
        if(config.HideSidebarIn.includes(location.pathname)){
            document.getElementById("app-sidebar").classList.add("w-0")
        }
        else if(document.getElementById("app-sidebar").classList.contains("w-0")){
            document.getElementById("app-sidebar").classList.remove("w-0")
        }
    }, [location.pathname])

    useEffect(() => {
        UserService.getSidebarItems()
        .then((res) => setSidebarItems(res))
        .catch((err) => console.error(err))
    }, [])

    return(
        <div className={`${config.HideSidebarIn.includes(pathName) ? "hidden" : "flex max-w-[265px] h-full"} select-none flex-col justify-between p-3 bg-blue-500`} style={{display:config.HideSidebarIn.includes(pathName) ? "none !importent" : ""}}>
            <div>
                <div className='mt-10 p-2 px-4'>
                    <img src={sidebarLogo} alt='Shashah'/>
                </div>

                <div className='mt-16 m-auto text-white mx-2 space-y-4'>
                    {sidebarItems.map((item, index) => {
                        return(
                            <Link to={item.link} key={index} className={`w-full flex justify-around rtl:space-x-reverse space-x-6 md:text-xs ${pathName === item.link || item.subs.some(sub => sub.link === pathName) ? "bg-blue-800" : "bg-blue-700"} rounded-lg p-3 items-center hover:bg-blue-800 cursor-pointer select-none`}>
                                <div>{item.icon}</div>
                                <div className='flex-grow'> {t(item.title)}</div>
                            </Link>
                        )
                    })}
                </div>
            </div>
            
            <div>
                <div className='justify-center self-center items-center rtl:space-x-reverse space-x-1 text-xs border w-fit m-auto px-4 py-1 rounded-lg text-white hidden'>
                    <SignOut  size={'0.75rem'}/>
                    <div>{t('button_logout')}</div>
                </div>
                <div className='flex justify-center items-center p-2 rtl:space-x-reverse space-x-3'>
                    <button className='text-white' onClick={() => i18n.changeLanguage('en')}>EN</button>
                    <button className='text-white' onClick={() => i18n.changeLanguage('ar')}>AR</button>
                </div>
                <div className='text-center text-white font-bold text-sm'>
                    V 1.0
                </div>
            </div>
        </div>
    )
}

export default Sidebar