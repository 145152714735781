


export const useOrdringSystem = (data, setData) => {

    // a little function to help us with reordering the result
    const reorder =  (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
    
        return result;
    }

    // update the order field 
    const updateOrder = () => {
        const result = Array.from(data.content)


        for (let index = 0; index < result.length; index++) {
            const element = result[index];
            element.order = index
        }

        return result
    }


    const onDragEnd = (result) => {
        if(!result.destination) {
            return; 
        }

        
        // console.log('new state=', items)
        
        // console.log(`moved item ${data.content[result.source.index].id} to index ${result.destination.index}`)
        // updateOrder(result.source.index, 99, result.destination.index, data.content)

        
        const items = reorder(
            data.content, 
            result.source.index, 
            result.destination.index
        );

        setData((prev) => {
            prev.content = items
            return prev
        })
    }

    return { onDragEnd,  updateOrder}
}