import UserService from "./UserService"


const loginStatus = (url, header, res) => {
    if(res.status === 403 || res.status === 401){
        UserService.removeToken()
        window.location = "/login"
    }
}


const profileStatus = (url, header, res) => {
    if(res.status === 405){
        

        setTimeout(() => window.location = "/setup-account", 500)
    }
}








const middlewareService = {
    profileStatus,
    loginStatus

}

export default middlewareService