import { useEffect } from "react"
import { useState } from "react"
import UserService from "../api/UserService"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"



const MobileSidebar = () => {
    const { t } = useTranslation()
    const [sidebarItems, setSidebarItems] = useState([])

    useEffect(() => {
        UserService.getSidebarItems()
        .then((res) => setSidebarItems(res))
        .catch((err) => console.error(err))
    }, [])


    return(
        <div className="flex w-screen rtl:space-x-reverse space-x-4 overflow-x-auto p-2">
            {sidebarItems.map((item, index) => (
                <div key={index} className="min-w-[10rem] text-xs bg-blue-600 rounded-lg p-3 items-center hover:bg-blue-800 cursor-pointer select-none text-white">
                    <Link to={item.link} key={index} className={`flex items-center rtl:space-x-reverse space-x-1`}>
                        <div>{item.icon}</div>
                        <div className='flex-grow'> {t(item.title)}</div>
                    </Link>
                </div>
            ))}
        </div>
    )
}

export default MobileSidebar