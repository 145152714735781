import { useState } from "react"
import { toastError } from "../api/apiUtils"
import PlaylistService from "../api/PlaylistService"
import { useEffect } from "react"



const usePlaylists = () => {
    const [playlists, setPlaylists] = useState([])
    const [isUsePlaylistsLoading, setisUsePlaylistsLoading] = useState(false)
    useEffect(() => init(), [])

    const init = () => {
        setisUsePlaylistsLoading(true)
        PlaylistService.all(1, 500)
        .then((res) => setPlaylists(res || []))
        .catch((e) => toastError(e.message))
        .finally(() => setisUsePlaylistsLoading(false))
    }
    

    

    return { 
        playlists,
        load:init,
        isUsePlaylistsLoading
    }
}

export default usePlaylists