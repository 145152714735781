import { useEffect } from "react"
import UserService from "../api/UserService"
import { useTranslation } from "react-i18next"
import { CircleNotch } from "phosphor-react"
import { useState } from "react"
import StatsCard from "../components/StatsCard"
import { toastError } from "../api/apiUtils"



const MainPage = () => {
    const { t } = useTranslation()
    const [isLoading, setIsloading] = useState(!true)
    const [data, setData] = useState(!true)


    const init = () => {
        setIsloading(true)
        UserService.getHome()
        .then((res) => setData(res))
        .catch((e) => toastError(e.message))
        .finally(() => setIsloading(false))
    }

    useEffect(() => {
        init()
    }, [])


    return(
        <div className="">

            <div className="grid grid-flow-row md:grid-cols-3 m-auto mt-5 gap-x-10 gap-y-4 place-items-center">
                {
                    data?.stats?.map((stat) => (
                        <StatsCard key={stat.t} title={t(stat.t)} value={stat.value} isLoading={isLoading} />
                    ))
                }
                
            </div>


        </div>
    )
}

export default MainPage